
  import { Component, Vue, Emit, Prop } from "vue-property-decorator";
  import goodsDeatil from "~/utils/goodsDetail.js";
  import { getNewsInfoHttp } from "~/api/comm.js";

  import newImg from "../newImg/index.vue";
  @Component({
    name: "detailGoodsDetail2",
    components: {
      newImg: require("~/components/comm/newImg/index.vue").default,
      detailAccountScreenshot:
        require("~/components/comm/goods/detailAccountScreenshot.vue").default,
    },
  })
  export default class detailGoodsDetail2 extends Vue {
    @Prop(Object) goodsInfo_ ?: any;


    public goodsDesc : any = []; // 商品描述列表



    public isExpand : boolean = false;
    public showMoreButton : boolean = false;

    public changeToMobileUrl : any; //调用公告方法plugins/mixin.js
    public copy : any;
    public myIndex : number = 0;
    private QRCode : any;
    public hidden : number = 1;
    private a : boolean = false;
    public isShowMobileFloat : boolean = false;
    private showMoreText : string = "更多";
    private detail : any = {};
    private detail2 : any = {};
    private whichPlatform : string = "";
    public purchaseProcess : any = "";
    public purchaseTips : any = "";
    private errorToastFn : any;
    public showGoodsDesc : boolean = false;
    public showGoodsInfo : boolean = false;
    public isGoodsInfoMoreBtn : boolean = true;
    private shopDetail_data : any = {}; // 新数据商品详情数据
    private shopRole_data : any = []; // 新数据角色数据
    private shopEquip_data : any = []; // 新数据装备数据
    private activeIndex : number = 1; // 下标
    private roleIndex : number = 1; // 下标

    mounted() {
      setTimeout(() => {
        console.log("goodsInfo_", this.goodsInfo_);
      }, 3000);
      this.whichPlatform = window.location.host;
      this.showMobileErWeiMa(true);
      // 详情处理
      this.detail = goodsDeatil;
      console.log("全部数据", this.goodsInfo_);
      if (this.goodsInfo_.added) {
        const result = this.remakeData(JSON.parse(this.goodsInfo_.added));
        console.log("我得到的新数据", result);
        result.forEach((item : any) => {
          if (item.name == "公共属性") {
            this.shopDetail_data = item;
          } else {
            item.children = item.children.filter((obj : any) => {
              if (obj.name == "装备列表") {
                this.shopEquip_data.push(obj);
              } else {
                return obj;
              }
            });
            this.shopRole_data.push(item);
          }
        });
        console.log("新数据商品详情数据", this.shopDetail_data);
        console.log("新数据角色数据", this.shopRole_data);
        console.log("新数据装备数据", this.shopEquip_data);
      }

      try {
        var data = this.detail[String(this.goodsInfo_.gameId)];
        this.detail2 = {};
        if (!!data && data[this.goodsInfo_.goodsTypeId]) {
          this.detail2 = { ...data[this.goodsInfo_.goodsTypeId] };
        } else {
          this.detail2 = { ...data[1] };
        }
      } catch (e) {
        this.detail2 = {};
      }
      let getTitleList = this.getTitleList;
      console.log(
        this.getTitleList,
        Math.ceil(getTitleList[0].length / 3),
        "你好啊~1231312！6！"
      );
      this.isGoodsInfoMoreBtn =
        getTitleList[0].length > 0
          ? Math.ceil(getTitleList[0].length / 3) + getTitleList[1].length <= 10
            ? false
            : true
          : getTitleList[1].length <= 10
            ? false
            : true;

      this.showMoreButton =
        getTitleList[0].length > 0
          ? Math.ceil(getTitleList[0].length / 3) + getTitleList[1].length <= 9
            ? false
            : true
          : getTitleList[1].length <= 9
            ? false
            : true;
      // this.handleGameTags(this.goodsInfo_.gameId);





      // console.log('this.goodsInfo_', this.goodsInfo_);
      // console.log('goodsShowTileList', this.goodsInfo_.goodsShowTileList);
      this.goodsInfo_.goodsShowTileList.forEach((item : any) => {
        let arr = item.key.split(',');
        let val : any;
        arr.forEach((key : any, index : number) => {
          if (val) {
            val = val + '-' + this.goodsInfo_[key];
          } else {
            val = this.goodsInfo_[key];
          }
        });
        let obj = {
          title: item.title,
          value: val
        }
        this.goodsDesc.push(obj);
      });
      console.log('this.goodsDesc', this.goodsDesc);

    }

    expandFold() {
      this.isExpand = !this.isExpand;
    }

    handleClick(tab : any) {
      this.activeIndex = 1;
    }

    remakeData(datasource : any) : any {
      return Object.keys(datasource).map((item, index) => {
        const value = datasource[item];
        if (typeof value === "object" && value !== null) {
          // 如果值是对象且非null，则递归调用
          return {
            name: item,
            id: index,
            children: this.remakeData(value),
          };
        } else {
          // 如果值不是对象，直接返回该值（但通常我们会希望有不同的处理方式）
          // 这里我们简单地将值放在一个数组中，以匹配其他对象的 'children' 结构
          return {
            name: item,
            id: index,
            children: value,
          };
        }
      });
    }








    updateMyIndex(index : any) {
      this.myIndex = index;
      console.log("this.index", this.myIndex);
      if (index == 1 && !this.purchaseProcess) {
        this.getPurchaseProcess();
      }
    }

    get getTitleList() {
      console.log(this.goodsInfo_, "你好啊~1231312！！");
      if (this.goodsInfo_.goodsShowTileList) {
        let flag : any = this.goodsInfo_.goodsShowTileList.filter((v : any) => {
          return v.type == "noDetail";
        });
        let data : any = [[], [], []];
        this.goodsInfo_.goodsShowTileList.map((v : any) => {
          // console.log('你好啊',v.key.length,v.key.substring(v.key.length-6))
          if (
            v.type == "noDetail" &&
            (v.key.substring(v.key.length - 6) === "Number" ||
              v.key.substring(v.key.length - 4) === "Name")
          ) {
            data[0].push(v);
          } else if (
            v.type == "noDetail" &&
            v.key.substring(v.key.length - 5) === "Names" &&
            this.getGoodsDetailValue(v.key) !== "无"
          ) {
            data[1].push(v);
          } else if (v.type == "noDetail") {
            data[2].push(v);
          }
        });
        console.log("你好啊！！！！！", data);

        return data;
      } else {
        return [];
      }
    }
    @Emit()
    show(v : number) {
      this.hidden = v;
    }
    @Emit()
    showMore() {
      this.a = !this.a;
      if (!this.a) {
        this.showMoreText = "更多";
      } else {
        this.showMoreText = "收起";
      }
    }
    @Emit()
    //生成二维码
    showMobileErWeiMa(flag : boolean) {
      this.isShowMobileFloat = flag;
      if (flag == true) {
        let url = window.location.origin + "/detail/" + this.$route.params.sn;
        let msgDom = null;
        msgDom = document.getElementById("canvasQR");
        console.log("routerouterouteroute", url, msgDom);
        if (!!msgDom) {
          console.log("QRCode", this.QRCode);
          this.QRCode.toCanvas(msgDom, url, async (error : any) => {
            console.log('"二维码错误"', error);
          });
        }
      }
    }
    @Emit()
    getGoodsDetail() {
      if (this.goodsInfo_.goodsShowTileList) {
        // console.log(this.goodsInfo_.goodsShowTileList.filter((v:any) => v.type=='noDetail'),'===goodsShowTileList==哈哈哈1=')
        let flag : any = this.goodsInfo_.goodsShowTileList.filter(
          (v : any) => v.type == "noDetail"
        );
        return flag;
      } else {
        return [];
      }
    }
    @Emit()
    getGoodsDetailValue(key : string) {
      let flagArray : any = key.split(",");
      let flagValue : string = "";
      for (let i : number = 0; i < flagArray.length; i++) {
        if (this.goodsInfo_ && this.goodsInfo_[flagArray[i]]) {
          if (flagValue) {
            flagValue = `${flagValue}-${this.goodsInfo_[flagArray[i]]}`;
          } else {
            flagValue = this.goodsInfo_[flagArray[i]];
          }
        }
      }
      if (flagValue) {
        return flagValue;
      } else {
        return "无";
      }
    }
    // 点击进群
    @Emit()
    joinToGroup(encryptNumber : any) {
      window.open(encryptNumber, "_blank");
    }
    // 交易须知
    @Emit()
    async getPurchaseProcess() {
      try {
        const { data } : any = await getNewsInfoHttp({
          params: {
            newsId: this.goodsInfo_.gameId,
            gameId: this.goodsInfo_.gameId,
            scene: 1,
            newsType: 0,
          },
        });
        if (this.errorToastFn(data)) {
          this.purchaseProcess = data.data.content;
        } else {
          this.purchaseProcess = "";
        }
      } catch (error) {
        this.purchaseProcess = "";
      }
    }
    // 交流群
    @Emit()
    async getPurchaseTips() {
      try {
        const { data } : any = await getNewsInfoHttp({
          params: {
            newsId: 108,
            gameId: this.goodsInfo_.gameId,
            scene: 1,
            newsType: 0,
          },
        });

        if (this.errorToastFn(data)) {
          this.purchaseTips = data.data.content;
        } else {
          this.purchaseTips = "";
        }
      } catch (error) {
        this.purchaseTips = "";
      }
    }

    showGoodsInfoTitle(index : any, index2 : any) {
      let data = this.getTitleList;
      console.log(this.getTitleList, "xiaolongdemo666");
      if (this.showGoodsInfo) {
        return true;
      } else {
        return index === 0
          ? true
          : data[0].length > 0
            ? Math.ceil(data[0].length / 3) + index2 + 1 <= 10
            : 0 + index2 + 1 <= 10;
      }
    }

    moreGoodsInfo(v : any) {
      if (v === 1) {
        this.showGoodsDesc = !this.showGoodsDesc;
      } else if (v === 0) {
        this.showGoodsInfo = !this.showGoodsInfo;
      }
    }
  }
